<template>
	<div>
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="$emit('goBack')"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area justify-space-between items-center">
						<div>
							<h2 class="page_tit"> {{ program.title }} </h2>
							<span class="page_subtit">{{  item_cartel.cartl_name }}</span>
						</div>
						<div
							class="save"
						>
							<button
								@click="onSubscribe"
								class="btn_save"
							>{{ $language.cartel_new.btn_subscribe }} <!-- 구독 --></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div class="section_wrap pt-80 size-px-14">
			<div class="subindex_head">
				<div class="container">
					<div class="subindex_box_1">
						<div class="text-center pa-20 under-line">
							<div class="img-box-100 ma-auto radius-20">
								<img :src="item_plan.payment_subscrp_plan_img_url" @error="$bus.$emit('onErrorImage', $event)" class="object-cover" />
							</div>

							<div
								v-if="item_plan.payment_subscrp_plan_number"
								class="mt-10"
							>
								<div class="mt-10 font-weight-700 size-px-16 ">{{ item_plan.payment_subscrp_plan_name }}</div>
								<div class="mt-10 size-px-16 font-weight-600 color-blue"><img :src="require('@/assets/image/icon_won_blue.svg')" /> {{ item_plan.payment_subscrp_plan_price | makeComma }}원</div>
								<div class="mt-10 size-px-13 color-blue-mafia color-blue-mafia">
									{{ item_plan.payment_subscrp_enddate.slice(0, 10) }}
									{{ $language.cartel_new.title_finish }} <!-- 종료 -->
								</div>
							</div>
							<div
								v-else
								class="mt-20 size-px-16 font-weight-500"
							>{{ $language.cartel_new.error_not_subscribe_plan }}<!-- 이용중인 구독 플랜이 없습니다.--></div>
						</div>
						<ul class="mt-30">
							<li
								v-for="(plane, p_index) in plane_list"
								:key="'plane_' + p_index"
								class="justify-space-between mb-20 cursor-pointer"
								@click="toDetail(plane)"
							>
								<div class="img-box-100 mr-10 radius-20 overflow-hidden"><img :src="plane.subscrp_plan_img_url" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/></div>
								<div class="flex-1 flex-column justify-center size-px-14 text-left">
									<div class="font-weight-500">{{ plane.subscrp_plan_name }}</div>
									<div class="mt-10 size-px-16 color-blue font-weight-600"><img :src="require('@/assets/image/icon_won_blue.svg')" />{{ plane.subscrp_plan_price | makeComma }}원</div>
									<div
										v-if="plane.is_history"
										class="mt-10 color-blue-mafia size-px-13"
									>{{ $language.cartel_new.txt_subscribe_history_ok }} <!-- 구독 내역이 있습니다. --></div>
									<div
										v-else
										class="mt-10 color-gray size-px-13"
									>{{ $language.cartel_new.txt_subscribe_history_no }} <!-- 구독 내역이 없습니다. --></div>
								</div>
								<div
									v-if="plane.is_history"
									class="flex-column justify-center"
								>
									<button
									><v-icon>mdi-chevron-right</v-icon></button>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<CartelSubscribe
			v-if="is_on_subscribe"
			:user="user"
			:plan_info="item_plan"

			@cancel="offSubscribe"
		></CartelSubscribe>
	</div>
</template>

<script>
import CartelSubscribe from "@/view/SubscribeCartel/CartelSubscribe";
export default {
	name: 'SubscribePlane'
	, components: {CartelSubscribe}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: this.$language.cartel_new.title_subscribe_history // 구독 내역
				, title: this.$language.cartel_new.title_subscribe_history // 구독 내역
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'
				, callBack: {
					name: 'subscribe'
					, title: this.$language.cartel_new.btn_subscribe // 구독
				}

			}
			, item_cartel: {}
			, item_plan: {}
			, items_plane: []
			, is_on_subscribe: false
		}
	}
	, computed: {
		plane_list: function(){
			return this.items_plane.filter( (item) => {
				item.is_history = false

				if(item.subscrp_particulars_fg == 'Y'){
					item.is_history = true
				}

				return item
			})
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_subscribe_user_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_plan = result.data.current_subscrp_plan
					this.items_plane = result.data.cartl_member_subscrp_plan_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			if(item.is_history){
				this.$bus.$emit('to', { name: 'CartelSubscribeHistory', params: { idx: this.$route.params.idx, p_id: item.subscrp_plan_number, type: item.subscrp_particulars_fg}})
			}
		}
		, onSubscribe: function(){
			this.is_on_subscribe = true
		}
		, offSubscribe: function(){
			this.is_on_subscribe = false
		}
		, getPlane: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_subscribe_payment
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, subscrp_plan_number: this.$route.params.p_id
					}
					, type: true
				})

				if(result.success){
					this.item_plan = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getCartel()
		await this.getData()
	}
}
</script>

<style>
.color-primary { color: var(--blue01) !important;}

</style>